import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { CatalogViewerNavControls } from "@app/super/catalog-viewer-nav-controls";
import { MetadataDownloadService } from "@services/metadata-download.service";
import { HasMarkedTextDirective } from "../../directives/has-marked-text.directive";

@Component({
	selector: "app-htmlviewer",
	templateUrl: "./htmlviewer.component.html",
	styleUrls: ["./htmlviewer.component.scss"],
	standalone: true,
	encapsulation: ViewEncapsulation.ShadowDom,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [HasMarkedTextDirective]
})
export class HtmlviewerComponent extends CatalogViewerNavControls implements OnInit, OnDestroy {
	public html: SafeHtml;


	constructor(
		private sanitizer: DomSanitizer,
		protected metadataDownloadService: MetadataDownloadService
	) {
		super(
			metadataDownloadService
		);
	}
	ngOnDestroy(): void {
		this.deleteCanonical();
	}

	ngOnInit() {
		if (this.attachment) {
			this.html = this.sanitizer.bypassSecurityTrustHtml(
				this.attachment?.text ?? ""
			);
			this.initCanonical();
		}
	}
}
